<template>
  <div class="loader icon-loader"></div>
</template>
<style lang="postcss">
.loader {

  @apply relative w-[1em] h-[1em] -my-[1em] rounded-full;
  font-size: 1.9em;

  &::before {
    font-size: 1em;
  }

  &::after {
    @apply absolute left-0 top-0 right-0 bottom-0;
    content: '';
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    clip-path: polygon(0% 0%, 0% 50%, 50% 50%, 75% 0%);
    animation: loading-spinner 1.75s linear infinite;
  }

}
</style>